<template>
    <div class="py-5 w-full block mb-0" :style="data.Style">
        <div :class="data.ContainerClass">
            <div :class="data.Title.Class" v-if="data.Title">
                <span :class="data.Title.TitleClass">{{ data.Title.Title }}</span>
                <span :class="data.Title.SubTitle">{{ data.Title.SubTitle }}</span>
            </div>
            <div class="sm:block md:flex block justify-center">
                <div v-for="(item, n) in data.Data" :key="n" :class="item.Class">
                    <div class="my-auto">
                        <img :src="item.Image" alt="">
                    </div>
                    <div :class="item.Text.Class">
                        <span class="block mb-0 font-semibold">{{ item.Text.Title }}</span>
                        <small>{{ item.Text.Description }}</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object
        }
    }
}
</script>
